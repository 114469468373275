import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: "/auth/login"
    },
    {
        path: '/auth/login',
        name: 'Login',
        component: () => import("../views/authentication/login.vue"),
        props: true
    },
    {
        path: '/timesheets/:doctorId',
        name: 'Home',
        component: () => import("../views/timesheets/index.vue"),
        props: true
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import("../views/admin/menu.vue"),
        props: true,
        children: [
            {
                path: 'doctors',
                name: 'Admin - Doctors',
                component: () => import("../views/admin/doctors/index.vue"),
                props: true
            },
            {
                path: 'locations',
                name: 'Admin - Locations',
                component: () => import("../views/admin/locations/index.vue"),
                props: true
            },
            {
                path: 'users',
                name: 'Admin - Users',
                component: () => import("../views/admin/users/index.vue"),
                props: true
            },
            {
                path: '/users/settings',
                name: 'Change Password',
                component: () => import("../views/users/change-password.vue"),
                props: true
            },

        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
